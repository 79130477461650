import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout";

// markup
const NotFoundPage = () => (
    <Layout>
        <>
        This page doesn't exist. <Link to="/">Home</Link>
        </>
    </Layout>
);

export default NotFoundPage;
